import state from "./moduleRegistrationState.js";
import mutations from "./moduleRegistrationMutations.js";
import actions from "./moduleRegistrationActions.js";
import getters from "./moduleRegistrationGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters,
};
