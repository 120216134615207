import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import VueApollo from "vue-apollo";
import Vue from "vue";
import store from "./store";
import config from "./config";

// Error Handling
const errorHandler = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    console.log("graphQL Errors:", graphQLErrors);
  } else if (networkError) {
    console.log("network Error:", networkError);
    const context = operation.getContext();
    if (context) {
      const { status } = context.response;
      // when the status code is 504 means, it is a timeout error
      if (status === 504) {
        localStorage.setItem("timeOutError", true);
      }
    }
  }
});
//configure the auth token
const authHeader = setContext((_, { headers }) => {
  const user_ip = store.state.userIpAddress;
  return {
    headers: {
      ...headers,
      user_ip: user_ip,
    },
  };
});

const httpLinkA = new HttpLink({
  uri: config.graphql_endpoint.signUp,
});

const httpLinkB = new HttpLink({
  uri: config.graphql_endpoint.planDetails,
});

const authLink = authHeader;

const errorLink = errorHandler;

//composing all configuration in link using apollolink
const linkA = ApolloLink.from([authLink, errorLink, httpLinkA]);
const linkB = ApolloLink.from([errorLink, httpLinkB]);

// Create the two apollo client instance
const apolloClientA = new ApolloClient({
  link: linkA,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});

const apolloClientB = new ApolloClient({
  link: linkB,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== "production", //enable dev tool only on development
});

function apolloConfig() {
  return () => {
    // Install the Vue plugin
    Vue.use(VueApollo);
    // Create vue apollo provider
    const apolloProvider = new VueApollo({
      clients: {
        apolloClientA,
        apolloClientB,
      },
      defaultClient: apolloClientA,
    });
    return apolloProvider;
  };
}

export const createProvider = apolloConfig();
