import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createProvider } from "./apolloConfiguration";
import vuetify from "./plugins/vuetify";
import * as inputValidators from "./inputValidators";

// Globally Registered Base Components
import "./components/globalComponent.js";

// Firebase
import "./firebase/firebaseConfig";

// configure axios globally
//$http hold axios object so can access axios using $http inside app
import Axios from "axios";
Vue.prototype.$http = Axios;

// import vee-validate components - input validation
import { ValidationObserver, ValidationProvider } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.config.productionTip = false;

new Vue({
  inputValidators,
  router,
  store,
  apolloProvider: createProvider(),
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
