const state = {
  windowWidth: null, //used to access screen size changes in all component
  isMobileWindowSize: false, //to check mobile view window size
  darkMode: false, //enable Theme
  //browser support webp
  isWebpSupport: false,
  //snackbar data
  showSnackbar: false,
  snackBarMessage: "",
  snackBarType: "warning",
  userIpAddress: "", // user public ip address
  userCountry: "", // user country based on ip address
  apiTimeOut: 60000, // 60 secs
};
export default state;
