<template>
  <div id="app">
    <v-app>
      <AppSnackBar
        v-if="snackbarData.isOpen"
        :show-snack-bar="snackbarData.isOpen"
        :snack-bar-msg="snackbarData.message"
        :image-name="snackbarData.image"
        :snack-bar-type="snackbarData.type"
        @close-snack-bar="closeSnackbarAlert"
      >
      </AppSnackBar>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>
//to check whether browser support webp
import supportsWebP from "supports-webp";

export default {
  name: "App",
  computed: {
    //show/hide sanckbar based on store data
    snackbarData() {
      return this.$store.getters.getSnackbarData;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    this.$store.dispatch("fetchUserIp");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    },
    //function to close the snackbar alert
    closeSnackbarAlert() {
      this.$store.commit("CLOSE_SNACKBAR");
    },
    // check whether browser supports webp or not and save the value in store
    async checkSupportWebp() {
      if (await supportsWebP) {
        this.$store.commit("UPDATE_WEBP_SUPPORTS", true);
      } else {
        this.$store.commit("UPDATE_WEBP_SUPPORTS", false);
      }
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  z-index: 30;
  width: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: none;
  border: 0;
}

::-webkit-scrollbar-track {
  background-color: #eaeaea;
}
</style>
