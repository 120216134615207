<template>
  <div
    class="full-height d-flex justify-center flex-column"
    :class="{ 'mt-sm-12': emailVerificationStatus }"
  >
    <ValidationObserver ref="observer">
      <v-form>
        <v-row class="px-sm-12" justify="center">
          <v-col cols="12" sm="6" md="5" lg="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="First name"
              rules="required|max:50|multilingualName"
            >
              <v-text-field
                v-model="firstName"
                outlined
                color="secondary"
                :error-messages="errors"
                type="text"
                autocomplete="off"
                @change="validateUpdateUserDetails()"
              >
                <template v-slot:label>
                  First Name<span class="red--text font-weight-bold pl-1"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="4">
            <ValidationProvider
              v-slot="{ errors }"
              name="Last name"
              rules="required|max:50|multilingualName"
            >
              <v-text-field
                v-model="lastName"
                outlined
                color="secondary"
                :error-messages="errors"
                type="text"
                autocomplete="off"
                @change="validateUpdateUserDetails()"
              >
                <template v-slot:label>
                  Last Name<span class="red--text font-weight-bold pl-1"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="userDefaultCountry"
            cols="12"
            md="10"
            lg="8"
            class="mb-6"
          >
            <VuePhoneNumberInput
              v-model="intelInputMobileNo"
              :default-country-code="userDefaultCountry"
              :preferred-countries="['IN', 'US', 'AU']"
              :error="!!mobileNumberValidation"
              error-color="#E53935"
              valid-color="#9E9E9E"
              :translations="mobileNumberTranslation"
              @update="updateMobileNumber($event)"
              @phone-number-blur="validateUpdateUserDetails()"
            ></VuePhoneNumberInput>
            <span class="red--text caption mt-1">{{
              mobileNumberValidation
            }}</span>
          </v-col>
          <v-col cols="12" md="10" lg="8" class="mb-3">
            <ValidationProvider
              v-slot="{ errors }"
              name="Business email address"
              rules="required|max:50|email"
            >
              <v-text-field
                v-model="emailAddress"
                outlined
                color="secondary"
                type="text"
                autocomplete="off"
                :error-messages="errors"
                :error="!!emailIdValidationMessage"
                @change="onChangeEmail()"
                @input="emailIdValidationMessage = ''"
              >
                <template v-slot:label>
                  Business Email Address
                  <span class="red--text font-weight-bold pl-1">*</span>
                </template>
              </v-text-field>
              <div
                v-if="errors && errors.length === 0"
                class="caption mt-n4 red--text"
              >
                {{ emailIdValidationMessage }}
              </div>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <ValidationObserver ref="nextObserver">
      <v-form v-if="emailVerificationStatus">
        <v-row
          v-if="emailVerificationStatus === 'verified'"
          justify="center"
          class="px-12 pt-2 mb-n6"
        >
          <v-col cols="12" md="10" lg="8">
            <div class="d-flex align-center pa-4 password-note-card">
              <v-icon color="secondary" size="30">info</v-icon>
              <div style="font-size: 14px" class="pl-1">
                Welcome back! Please use the same password that you have used
                earlier.
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center" class="px-12 pt-6">
          <v-col
            v-if="
              emailVerificationStatus === 'new' ||
              emailVerificationStatus === 'verified'
            "
            cols="12"
            md="10"
            lg="8"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Password"
              rules="required|max:30|min:6"
            >
              <v-text-field
                v-model="password"
                outlined
                color="secondary"
                :error-messages="errors"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : fieldTypes['password']"
                autocomplete="off"
                name="password"
                @focus="handleType"
                @blur="handleType"
                @click:append="showPassword = !showPassword"
              >
                <template v-slot:label>
                  Password<span class="red--text font-weight-bold pl-1">*</span>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="emailVerificationStatus && emailVerificationStatus === 'new'"
            cols="12"
            md="10"
            lg="8"
          >
            <ValidationProvider
              v-slot="{ errors }"
              name="Confirm password"
              :rules="confirmPasswordRules"
            >
              <v-text-field
                v-model="confirmPassword"
                outlined
                color="secondary"
                :error-messages="errors"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showConfirmPassword ? 'text' : fieldTypes['newPassword']"
                autocomplete="off"
                name="newPassword"
                @focus="handleType"
                @blur="handleType"
                @click:append="showConfirmPassword = !showConfirmPassword"
              >
                <template v-slot:label>
                  Confirm Password<span class="red--text font-weight-bold pl-1"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="10" lg="8">
            <ValidationProvider
              v-slot="{ errors }"
              name="Organization name"
              rules="required|min:3|max:100|alphaNumHyphenSpaceDotCommaAnd"
            >
              <v-text-field
                v-model="orgName"
                outlined
                color="secondary"
                :error-messages="errors"
                type="text"
                autocomplete="off"
                hint="e.g., ABC Pvt Ltd"
                persistent-hint
              >
                <template v-slot:label>
                  Name of your organization<span
                    class="red--text font-weight-bold pl-1"
                    >*</span
                  >
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="10" lg="8" class="mb-4">
            <ValidationProvider
              v-slot="{ errors }"
              name="Sub domain"
              rules="required|min:3|max:24|alphaNumeric"
            >
              <v-text-field
                v-model="orgCode"
                outlined
                color="secondary"
                type="text"
                :hint="
                  !orgCodeValidationMessage
                    ? 'e.g., your_subdomain.' + domainName
                    : ''
                "
                persistent-hint
                autocomplete="off"
                :error-messages="errors"
                :error="!!orgCodeValidationMessage"
                @change="validateOrgCode()"
                @focus="handleOrgCodeInputType"
                @blur="handleOrgCodeInputType"
              >
                <template v-slot:label>
                  Organization code for subdomain<span
                    class="red--text font-weight-bold pl-1"
                    >*</span
                  >
                </template>
                <template v-slot:append>
                  <div class="blue--text body-1 font-weight-bold">
                    {{ domainName }}
                  </div>
                </template>
              </v-text-field>
              <div
                v-if="errors && errors.length === 0"
                class="caption mt-n5 red--text"
              >
                {{ orgCodeValidationMessage }}
              </div>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="10" lg="8" class="mt-3">
            <ValidationProvider
              v-slot="{ errors }"
              name="Country"
              rules="required"
            >
              <v-autocomplete
                v-model="countryCode"
                outlined
                :items="countryList"
                item-text="name.common"
                item-value="cca2"
                :error-messages="errors"
              >
                <template v-slot:label>
                  Country<span class="red--text font-weight-bold pl-1">*</span>
                </template>
              </v-autocomplete>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <div class="d-flex align-center flex-column mt-4">
      <div id="recaptcha-container"></div>
      <v-btn
        id="recaptcha-container"
        class="my-3"
        rounded
        color="secondary"
        :disabled="isDisableNextButton"
        @click="validateFirstStep()"
        >Next <i class="fa fa-chevron-right pl-2"></i
      ></v-btn>
    </div>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import config from "../../config";
import {
  VALIDATE_EMAIL_ADDRESS,
  VALIDATE_ORGCODE,
  UPDATE_REGISTER_USER_DETAILS,
} from "@/graphql/registration/registrationQueries";
// import helper function
import { getErrorCodes, getErrorCodesWithMessages } from "@/helper";
import firebase from "firebase/app";
import "firebase/auth";

let isRecaptchaEnabled = false,
  recaptchaVerifier;
export default {
  name: "RegistrationStep1",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      emailVerificationStatus: "",
      emailIdValidationMessage: "",
      orgCodeValidationMessage: "",
      isLoading: false,
      firstName: "",
      lastName: "",
      mobileNo: null,
      mobileNoCountryCode: null,
      intelInputMobileNo: null,
      isMobileUpdated: false,
      mobileNumberProps: {},
      emailAddress: "",
      password: "",
      confirmPassword: "",
      orgName: "",
      orgCode: "",
      defaultCountries: [
        {
          cca2: "IN",
          name: {
            common: "India",
          },
        },
        {
          cca2: "SG",
          name: {
            common: "Singapore",
          },
        },
        {
          cca2: "US",
          name: {
            common: "United States",
          },
        },
        {
          cca2: "AU",
          name: {
            common: "Australia",
          },
        },
        {
          cca2: "Others",
          name: {
            common: "Others",
          },
        },
      ],
      countries: [],
      domainName: "",
      countryCode: "",
      isUserDetailsUpdated: false,
      fieldTypes: {
        password: "text",
        newPassword: "text",
      },
      mobileNumberTranslation: {
        phoneNumberLabel: "Mobile Number *",
      },
      isDisableNextButton: false,
    };
  },
  computed: {
    mobileNumberValidation() {
      if (this.isMobileUpdated && !this.mobileNo && !this.mobileNoCountryCode) {
        return "Mobile number is required";
      } else if (this.isMobileUpdated && Object.keys(this.mobileNumberProps)) {
        return this.mobileNumberProps.isValid
          ? ""
          : "Please provide a valid mobile number";
      }
      return "";
    },
    confirmPasswordRules() {
      return `required|passwordCompare:${this.password}`;
    },
    // user default country code based on IP address
    userDefaultCountry() {
      return this.$store.state.userCountry;
    },
    // return country list based on countries list API response
    countryList() {
      if (this.countries && this.countries.length > 0) {
        return this.countries;
      } else return this.defaultCountries;
    },
  },
  mounted() {
    this.domainName = config.domain;
    // set default country based on ip address
    this.countryCode = this.userDefaultCountry;
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: function () {
          // reCAPTCHA solved
          isRecaptchaEnabled = true;
        },
        "expired-callback": function () {
          // reCAPTCHA expired
          isRecaptchaEnabled = false;
        },
      }
    );
    window.recaptchaVerifier = recaptchaVerifier;
    // fetch countries list
    this.getCountries();
  },
  methods: {
    async getCountries() {
      let data = await this.$store.dispatch("registration/listCountries");
      if (data && data.length > 0) this.countries = data;
    },
    validateFirstStep() {
      if (this.isUserDetailsUpdated || !!this.emailVerificationStatus) {
        if (!this.isUserDetailsUpdated) {
          this.isMobileUpdated = true;
          this.$refs.observer.validate();
        }
        this.$refs.nextObserver.validate().then((validationResponse) => {
          // response will return true/false based on validation
          if (validationResponse) {
            if (!this.orgCodeValidationMessage && this.isUserDetailsUpdated) {
              if (isRecaptchaEnabled) {
                this.updateUserDetailsInStore();
                this.createVerifyUserInFirebase();
              } else {
                let snackbarData = {
                  isOpen: true,
                  type: "warning",
                  message: "Please confirm that you are not a robot.",
                };
                this.showAlert(snackbarData);
              }
            }
          }
        });
      } else {
        this.isMobileUpdated = true;
        this.$refs.observer.validate();
      }
    },
    validateUpdateUserDetails() {
      if (
        this.emailVerificationStatus &&
        (this.emailVerificationStatus === "new" ||
          this.emailVerificationStatus === "verified")
      ) {
        this.isMobileUpdated = true;
        this.$refs.observer.validate().then((validationResponse) => {
          // response will return true/false based on validation
          if (validationResponse) {
            if (
              !this.mobileNumberValidation &&
              !this.emailIdValidationMessage
            ) {
              this.isUserDetailsUpdated = true;
              this.updateUserDetailsInStore();
              this.updateUserDetails();
            }
          }
        });
      } else if (
        this.firstName &&
        this.lastName &&
        this.mobileNo &&
        this.mobileNoCountryCode &&
        this.emailAddress
      ) {
        this.validateBusinessEmail();
      }
    },
    // update the user details in store for global usage
    updateUserDetailsInStore() {
      let countryNameArr = this.countryList.filter(
        (el) => el.cca2 === this.countryCode
      );
      let registrationDetails = {
        firstName: this.firstName,
        lastName: this.lastName,
        mobileNo: this.mobileNo,
        mobileNoCountryCode: this.mobileNoCountryCode,
        emailAddress: this.emailAddress,
        orgName: this.orgName,
        orgCode: this.orgCode,
        countryCode: this.countryCode,
        countryName:
          countryNameArr && countryNameArr.length > 0
            ? countryNameArr[0].name.common
            : "",
      };
      this.$store.commit(
        "registration/UPDATE_USER_DETAILS",
        registrationDetails
      );
    },
    handleOrgCodeInputType(event) {
      const { type } = event;
      if (type === "blur") {
        this.isDisableNextButton = false;
      } else {
        this.isDisableNextButton = true;
      }
    },
    // handle password field types on focus/blur to avoid auto-fill
    handleType(event) {
      const { srcElement, type } = event;
      const { name, value } = srcElement;
      // set field type as text on blur event with empty value
      if (type === "blur" && !value) {
        this.fieldTypes[name] = "text";
      } else {
        // otherwise set it as password
        this.fieldTypes[name] = "password";
      }
    },
    updateMobileNumber(payload) {
      if (payload && Object.keys(payload).length > 0) {
        this.mobileNo = payload.nationalNumber;
        this.mobileNoCountryCode = "+" + payload.countryCallingCode;
        this.countryCode = payload.countryCode;
      }
      this.isMobileUpdated = true;
      this.mobileNumberProps = payload;
    },
    // check the entered email is already registered in firebase or not
    async checkVerifyEmail() {
      let verificationResponse = await this.$store.dispatch(
        "registration/checkVerifiedEmail"
      );
      if (
        verificationResponse &&
        (verificationResponse === "new" || verificationResponse === "verified")
      ) {
        this.emailVerificationStatus = verificationResponse;
        this.validateUpdateUserDetails();
        this.renderRecaptcha();
      } else {
        this.isLoading = false;
        let snackbarData = {
          isOpen: true,
          type: "warning",
          message: verificationResponse,
        };
        this.showAlert(snackbarData);
      }
      this.isLoading = false;
    },
    // render recaptcha block on screen when all the input fields are presented in step 1
    renderRecaptcha() {
      recaptchaVerifier.render();
    },
    // if the user is already registered, then we need to verify them
    async verifyUser() {
      let verifyUserResponse = await this.$store.dispatch(
        "registration/verifyFirebaseUser"
      );
      if (verifyUserResponse && verifyUserResponse === "success") {
        this.sendOtp();
      } else {
        this.isLoading = false;
        let snackbarData = {
          isOpen: true,
          type: "warning",
          message: verifyUserResponse,
        };
        this.showAlert(snackbarData);
      }
    },
    // if the user is not registered, then we have to create a user in firebase
    async createUser() {
      let createUserResponse = await this.$store.dispatch(
        "registration/createFirebaseUser"
      );
      if (createUserResponse && createUserResponse !== "error") {
        this.sendOtp();
      } else {
        this.isLoading = false;
        let snackbarData = {
          isOpen: true,
          type: "warning",
          message: createUserResponse,
        };
        this.showAlert(snackbarData);
      }
    },
    // send otp after verified/created user in firebase
    async sendOtp() {
      let snackbarData = {
        isOpen: true,
        type: "warning",
        message: "",
      };
      let otpSentStatus = await this.$store.dispatch(
        "registration/sendVerificationOTP"
      );
      if (otpSentStatus && otpSentStatus === "OTP Sent") {
        snackbarData.type = "success";
        snackbarData.message = "OTP sent successfully";
        this.$emit("next-step");
      } else {
        snackbarData.message = otpSentStatus;
      }
      this.isLoading = false;
      this.showAlert(snackbarData);
    },
    // to decide to call verify/create based on the email verification status
    createVerifyUserInFirebase() {
      this.isLoading = true;
      this.$store.commit("registration/UPDATE_PASSWORD", this.password);
      // verify user
      if (this.emailVerificationStatus === "verified") {
        this.verifyUser();
      }
      // create new user
      else if (this.emailVerificationStatus === "new") {
        this.createUser();
      }
    },
    updateUserDetails() {
      let vm = this;
      try {
        vm.$apollo
          .mutate({
            mutation: UPDATE_REGISTER_USER_DETAILS,
            client: "apolloClientA",
            variables: {
              firstName: vm.firstName,
              lastName: vm.lastName,
              mobileNo: vm.mobileNoCountryCode + vm.mobileNo,
              emailAddress: vm.emailAddress,
            },
          })
          .then((response) => {
            const { errorCode, hrappUserId } =
              response.data.updateRegistrationUserDetails;
            if (!errorCode) {
              vm.$store.commit(
                "registration/UPDATE_HRAPP_USER_ID",
                hrappUserId
              );
            } else {
              // need not handle any success / failure
            }
          })
          .catch(() => {
            // need not handle any success / failure
          });
      } catch {
        // need not handle any success / failure
      }
    },
    validateOrgCode() {
      let vm = this;
      vm.orgCodeValidationMessage = "";
      let fieldsErrorArray = vm.$refs.nextObserver.errors,
        validationErrorExists = true;
      // check there is no validation error
      if (
        (fieldsErrorArray["Sub domain"] &&
          fieldsErrorArray["Sub domain"].length) ||
        !vm.orgCode
      ) {
        validationErrorExists = true;
      } else {
        validationErrorExists = false;
      }
      if (!validationErrorExists) {
        vm.isLoading = true;
        try {
          vm.$apollo
            .query({
              query: VALIDATE_ORGCODE,
              client: "apolloClientA",
              fetchPolicy: "no-cache",
              variables: {
                orgCode: vm.orgCode,
              },
            })
            .then((response) => {
              const { errorCode } = response.data.validateOrgCodeExist;
              if (!errorCode) {
                vm.orgCodeValidationMessage = "";
              } else {
                vm.orgCodeValidationMessage = "Organization code already exist";
              }
              vm.isLoading = false;
            })
            .catch((error) => {
              vm.handleValidateOrgCodeError(error);
            });
        } catch (error) {
          vm.handleValidateOrgCodeError(error);
        }
      }
    },
    onChangeEmail() {
      this.emailIdValidationMessage = "";
      this.isMobileUpdated = true;
      if (
        (this.firstName &&
          this.lastName &&
          this.mobileNo &&
          this.mobileNoCountryCode &&
          this.emailAddress) ||
        this.emailVerificationStatus
      ) {
        this.validateBusinessEmail();
      } else {
        this.$refs.observer.validate();
      }
    },
    validateBusinessEmail() {
      let vm = this;
      let fieldsErrorArray = vm.$refs.observer.errors,
        validationErrorExists = true;
      // check there is no validation error
      if (
        (fieldsErrorArray["Business email address"] &&
          fieldsErrorArray["Business email address"].length) ||
        !vm.emailAddress
      ) {
        validationErrorExists = true;
      } else {
        validationErrorExists = false;
      }
      if (!validationErrorExists) {
        vm.isLoading = true;
        try {
          vm.$apollo
            .query({
              query: VALIDATE_EMAIL_ADDRESS,
              client: "apolloClientA",
              fetchPolicy: "no-cache",
              variables: {
                emailAddress: vm.emailAddress,
              },
            })
            .then((response) => {
              const { errorCode } = response.data.validateBusinessEmail;
              if (!errorCode) {
                vm.emailIdValidationMessage = "";
                vm.updateUserDetailsInStore();
                vm.checkVerifyEmail();
              } else {
                vm.emailIdValidationMessage =
                  "Please enter your business email address";
                vm.isLoading = false;
              }
            })
            .catch((error) => {
              vm.handleValidateBusinessEmailError(error);
            });
        } catch (error) {
          vm.handleValidateBusinessEmailError(error);
        }
      }
    },

    // handle validate org code error from BE
    handleValidateOrgCodeError(err) {
      this.isLoading = false;
      let snackbarData = {
        isOpen: true,
        type: "warning",
        message: "",
      };
      // check if it is a graphql error
      if (err && err.graphQLErrors) {
        // errorProperties is array. 0: errorCode, 0: errorMessage
        let errorProperties = getErrorCodesWithMessages(err);
        if (errorProperties && errorProperties.length > 0) {
          switch (errorProperties[0]) {
            case "_DB0000": // technical errors
              snackbarData.message =
                "It’s us ! There seems to be some technical difficulties while validating organization code. Please try after some time.";
              break;
            case "IVE0000": // validation error
            case "IVE0131": // org code already exist
              snackbarData.isOpen = false;
              this.orgCodeValidationMessage = errorProperties[1];
              break;
            case "_UH0001": // unhandled error
            case "RR0103": // Error while processing the request to validate the org code exist or not.
            default:
              snackbarData.message =
                "Something went wrong while validating organization code. If you continue to see this issue, please contact the platform administrator.";
              break;
          }
        } else {
          snackbarData.message =
            "Something went wrong while validating organization code. If you continue to see this issue, please contact the platform administrator.";
        }
      } else {
        snackbarData.message =
          "Something went wrong while validating organization code. If you continue to see this issue, please contact the platform administrator.";
      }
      this.showAlert(snackbarData);
    },
    // handle validate business email error from BE
    handleValidateBusinessEmailError(err) {
      this.isLoading = false;
      let snackbarData = {
        isOpen: true,
        type: "warning",
        message: "",
      };
      // check if it is a graphql error
      if (err && err.graphQLErrors) {
        let errorCode = getErrorCodes(err);
        switch (errorCode) {
          case "_DB0000": // technical errors
            snackbarData.message =
              "It’s us ! There seems to be some technical difficulties while validating email address. Please try after some time.";
            break;
          case "IVE0000": // validation error
          case "IVE0052": // please provide a valid email address
            snackbarData.isOpen = false;
            this.emailIdValidationMessage =
              "Please enter your business email address";
            break;
          case "_UH0001": // unhandled error
          case "RR0105": // Error while processing the request to validate the business email.
          default:
            snackbarData.message =
              "Something went wrong while validating email address. If you continue to see this issue, please contact the platform administrator.";
            break;
        }
      } else {
        snackbarData.message =
          "Something went wrong while validating email address. If you continue to see this issue, please contact the platform administrator.";
      }
      this.showAlert(snackbarData);
    },
    // show the message in snack bar
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>

<style lang="css">
.password-note-card {
  background: #f5f8ff;
}

.input-tel__input {
  box-shadow: none !important;
  min-height: 50px !important;
}
.input-tel__input:focus {
  border-width: 2px;
  border-color: var(--v-secondary-base) !important;
}
.country-selector__input {
  box-shadow: none !important;
  min-height: 50px !important;
  padding-top: 0px !important;
}
.vue-phone-number-input {
  min-height: 50px !important;
}
.country-selector__label {
  display: none;
}
.input-tel__label {
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 13px !important;
}
.country-selector__toggle {
  margin-top: 3px;
}
.country-selector__country-flag {
  margin-top: -3px !important;
}
.v-input__append-inner {
  cursor: pointer;
}
</style>
