import gql from "graphql-tag";

// ===============
// Queries
// ===============
export const VALIDATE_ORGCODE = gql`
  query validateOrgCodeExist($orgCode: String!) {
    validateOrgCodeExist(orgCode: $orgCode) {
      errorCode
      message
    }
  }
`;
export const VALIDATE_EMAIL_ADDRESS = gql`
  query validateBusinessEmail($emailAddress: String!) {
    validateBusinessEmail(emailAddress: $emailAddress) {
      errorCode
      message
    }
  }
`;

export const SEND_WELCOME_EMAIL = gql`
  query sendRegistrationEmailToClient(
    $emailId: String!
    $orgCode: String!
    $firstName: String!
    $lastName: String!
    $flowTrackRegistration: Int
  ) {
    sendRegistrationEmailToClient(
      emailId: $emailId
      orgCode: $orgCode
      firstName: $firstName
      lastName: $lastName
      flowTrackRegistration: $flowTrackRegistration
    ) {
      errorCode
      message
    }
  }
`;

// ===============
// Mutation
// ===============
export const UPDATE_REGISTER_USER_DETAILS = gql`
  mutation updateRegistrationUserDetails(
    $firstName: String!
    $lastName: String!
    $mobileNo: String!
    $emailAddress: String!
  ) {
    updateRegistrationUserDetails(
      firstName: $firstName
      lastName: $lastName
      mobileNo: $mobileNo
      emailAddress: $emailAddress
    ) {
      errorCode
      message
      hrappUserId
    }
  }
`;

export const ADD_REGISTRATION = gql`
  mutation addRegistration(
    $firstName: String!
    $lastName: String!
    $mobileNo: String!
    $mobileNoCountryCode: String!
    $emailAddress: String!
    $hrappUserUId: Int!
    $orgName: String!
    $orgCode: String!
    $countryCode: String!
    $countryName: String!
    $frequency: String
    $authenticationMethod: String
    $dashboardType: String!
  ) {
    addRegistration(
      firstName: $firstName
      lastName: $lastName
      mobileNo: $mobileNo
      mobileNoCountryCode: $mobileNoCountryCode
      emailAddress: $emailAddress
      hrappUserUId: $hrappUserUId
      orgName: $orgName
      orgCode: $orgCode
      countryCode: $countryCode
      countryName: $countryName
      frequency: $frequency
      authenticationMethod: $authenticationMethod
      dashboardType: $dashboardType
    ) {
      errorCode
      message
    }
  }
`;
