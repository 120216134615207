<template>
  <div class="full-height d-flex align-center">
    <v-row class="pa-12 d-flex align-center" justify="center">
      <v-col cols="12" lg="9">
        <div class="text-h5 grey--text">
          We have sent an email to {{ userDetails.emailAddress }}. Click the
          verification link in your email to confirm your email address and
          increase the productivity of your workforce with
          {{ registrationDomain }}.
        </div>
      </v-col>
      <v-col cols="12" lg="9" class="mt-6 d-flex align-center">
        <div class="secondary--text font-weight-bold text-h5">
          Redirecting to login page : {{ timerSeconds }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import config
import config from "../../config";

export default {
  name: "RegistrationStep3",

  data: () => ({
    timerSeconds: "",
    domainName: "",
  }),

  computed: {
    userDetails() {
      return this.$store.state.registration.userDetails;
    },
    registrationDomain() {
      return config.domainName;
    },
  },
  created() {
    this.domainName = config.domain;
    // set the countdown as 30sec to redirect the user to login page
    this.countDownTimer(30);
    // logout the user from current firebase session when the login redirection page presented
    this.$store.dispatch("registration/userLogout");
  },
  methods: {
    // launch to the registered domain
    launchPageRedirect() {
      const { orgCode } = this.userDetails;
      window.location.href = "https://" + orgCode + "." + this.domainName;
    },
    // countdown timer to reduce value one by one second(30sec to 0)
    countDownTimer(duration) {
      var timer = duration,
        seconds,
        timerFunction = setInterval(() => {
          seconds = parseInt(timer % 60, 10);
          seconds = seconds < 10 ? "0" + seconds : seconds;
          this.timerSeconds = "00:00:" + seconds;
          // when the timer reaches 0, we will redirect them to registers domain's login page
          if (--timer < 0) {
            clearInterval(timerFunction);
            this.launchPageRedirect();
          }
        }, 1000);
    },
  },
};
</script>
