// Parse the errorCodes that is thrown from BE using Apollo Server
export function getErrorCodes(errorList) {
  for (let err of errorList.graphQLErrors) {
    return err.extensions.code;
  }
}

export function getErrorCodesWithMessages(errorList) {
  for (let err of errorList.graphQLErrors) {
    return [err.extensions.code, err.message];
  }
}
