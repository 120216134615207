import firebase from "firebase/app";
import "firebase/auth";
import Vue from "vue";

export default {
  checkVerifiedEmail({ state }) {
    const { emailAddress } = state.userDetails;
    return new Promise((resolve) => {
      firebase
        .auth()
        .fetchSignInMethodsForEmail(emailAddress)
        .then((userRecord) => {
          if (userRecord && userRecord.length) {
            resolve("verified");
          } else {
            resolve("new");
          }
        })
        .catch((error) => {
          console.log("Email Verification Error:", error);
          switch (error.code) {
            case "auth/user-not-found":
            case "auth/network-request-failed":
            default:
              resolve("Something went wrong. Please try again.");
              break;
          }
        });
    });
  },
  createFirebaseUser({ state }) {
    const { emailAddress } = state.userDetails;
    return new Promise((resolve) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(emailAddress, state.password)
        .then(() => {
          resolve("success");
        })
        .catch((error) => {
          console.log("Email Creation Error:", error);
          switch (error.code) {
            case "auth/user-not-found":
            case "auth/network-request-failed":
            default:
              resolve("Something went wrong. Please try again.");
              break;
          }
        });
    });
  },
  verifyFirebaseUser({ state }) {
    const { emailAddress } = state.userDetails;
    return new Promise((resolve) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(emailAddress, state.password)
        .then(() => {
          resolve("success");
        })
        .catch((error) => {
          console.log("Verify User Error:", error);
          switch (error.code) {
            case "auth/wrong-password":
              resolve("Kindly enter valid password.");
              break;
            case "auth/too-many-requests":
            case "400":
              resolve(
                "Too many unsuccessful login attempts. Please try again later."
              );
              break;
            case "auth/invalid-email":
              resolve("Please provide valid email address.");
              break;
            case "auth/user-not-found":
            case "auth/network-request-failed":
            default:
              resolve("Something went wrong. Please try again.");
              break;
          }
        });
    });
  },
  sendVerificationOTP({ state }) {
    window.signingIn = true;
    let appVerifier = window.recaptchaVerifier;
    const { mobileNo, mobileNoCountryCode } = state.userDetails;
    console.log(mobileNoCountryCode, mobileNo);
    return new Promise((resolve) => {
      firebase
        .auth()
        .signInWithPhoneNumber(mobileNoCountryCode + mobileNo, appVerifier)
        .then((confirmationResult) => {
          window.signingIn = false;
          window.confirmationResult = confirmationResult;
          resolve("OTP Sent");
        })
        .catch((error) => {
          console.log("OTP Send Error:", error);
          window.signingIn = false;
          switch (error.code) {
            case "auth/invalid-phone-number":
              resolve("Mobile number is not valid.");
              break;
            case "auth/too-many-requests":
              resolve(
                "We have blocked all requests from this mobile number temporarily due to unusual activity. Please try again later."
              );
              break;
            default:
              resolve(
                "Something went wrong while sending OTP. Please try after some time."
              );
              break;
          }
        });
    });
  },
  verifyOTP({ state }) {
    return new Promise((resolve) => {
      window.confirmationResult
        .confirm(state.otp)
        .then(() => {
          resolve("OTP Verified");
        })
        .catch((error) => {
          console.log("OTP Verification Error:", error);
          switch (error.code) {
            case "auth/invalid-verification-code":
              resolve("Kindly enter valid OTP.");
              break;
            case "auth/code-expired":
              resolve(
                "The SMS code has expired. Please re-send the verification code to try again."
              );
              break;
            default:
              resolve(
                "Something went while verifying OTP. Please try after some time."
              );
              break;
          }
        });
    });
  },
  sendVerificationEmail({ state }) {
    const { emailAddress } = state.userDetails;
    return new Promise((resolve) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(emailAddress, state.password)
        .then(() => {
          firebase
            .auth()
            .currentUser.sendEmailVerification()
            .then(() => {
              resolve("Email Sent");
            })
            .catch((error) => {
              console.log("Send Verification Email Error:", error);
              switch (error.code) {
                case "auth/too-many-requests":
                case "400":
                  resolve(
                    "We have blocked all requests from this email temporarily due to unusual activity. Please try again later."
                  );
                  break;
                case "auth/user-not-found":
                case "auth/network-request-failed":
                default:
                  resolve(
                    "Something went wrong while sending verification email. Please try again."
                  );
                  break;
              }
            });
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/wrong-password":
              resolve("Wrong password");
              break;
            case "auth/too-many-requests":
            case "400":
              resolve(
                "Too many unsuccessful login attempts. Please try again later."
              );
              break;
            case "auth/invalid-email":
              resolve("Please provide valid email address.");
              break;
            case "auth/user-not-found":
            case "auth/network-request-failed":
            default:
              resolve(
                "Something went wrong while sending verification email. Please try again."
              );
              break;
          }
        });
    });
  },
  // logout Current User from firebase
  userLogout() {
    const firebaseCurrentUser = firebase.auth().currentUser;
    if (firebaseCurrentUser) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          // no need to handle success
        })
        .catch(() => {
          // no need to handle error
        });
    }
  },
  listCountries() {
    return new Promise((resolve) => {
      try {
        Vue.prototype.$http
          .get("https://restcountries.com/v3/all")
          .then((res) => {
            resolve(res ? res.data : []);
          })
          .catch(() => {
            resolve([]);
          });
      } catch {
        resolve([]);
      }
    });
  },
};
