import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#000000",
        secondary: "#005CFF",
        grey: "#9E9E9E",
        green: "#4CAF50",
        deepOrange: "#FF5722",
        lightblue: "#8b8d8e",
      },
      dark: {
        primary: "#000000",
        secondary: "#005CFF",
        grey: "#9E9E9E",
        green: "#4CAF50",
        deepOrange: "#FF5722",
        lightblue: "#8b8d8e",
      },
    },
  },
});
