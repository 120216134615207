import Vue from "vue";
import VueRouter from "vue-router";
import Registration from "../views/registration/Registration.vue";
import config from "../config";

Vue.use(VueRouter);

const routes = [
  {
    path: config.routingUrl,
    name: "Registration",
    component: Registration,
    meta: {
      title: "Registration",
    },
  },
  {
    path: "*",
    redirect: config.routingUrl,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

// Route pre-entry check
router.beforeEach((to, from, next) => {
  let domain = config.domain;
  if (domain === "cannyhr.com") {
    document.title = to.meta.title + " | CANNYHR";
  } else if (domain === "upshothr.uk") {
    document.title = to.meta.title + " | UPSHOTHR";
  } else {
    document.title = to.meta.title + " | HRAPP - Hire to Retire";
  }
  next();
});

export default router;
