import Vue from "vue";
import config from "../config";

export default {
  // Get user Ip address and country details
  async fetchUserIp({ commit }) {
    var userIpAddress = "",
      userCountry = "";
    await Vue.prototype.$http
      .get(config.ipAddressApi)
      .then((res) => {
        const { ip, country } = res.data;
        userIpAddress = ip;
        userCountry = country;
      })
      .catch(() => {
        userIpAddress = "-";
        userCountry = "IN"; // when error is ocurred we can present default country as india
      });
    commit("UPDATE_USER_INFO", [userIpAddress, userCountry]);
  },
};
