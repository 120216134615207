// NOTE
// Please use your own firebase details below.

import firebase from "firebase/app";
import config from "../config";

// Initialize Firebase
var firebaseConfig = {
  apiKey: config.firebase_credentials.apiKey,
  authDomain: config.firebase_credentials.authDomain,
  databaseURL: config.firebase_credentials.databaseURL,
  projectId: config.firebase_credentials.projectId,
  storageBucket: config.firebase_credentials.storageBucket,
  messagingSenderId: config.firebase_credentials.messagingSenderId,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp;
