<template>
  <div class="full-height d-flex align-center">
    <ValidationObserver v-if="!showLoadingScreens" ref="observer">
      <v-form>
        <v-row class="pa-12 d-flex align-center mt-8" justify="center">
          <v-col
            cols="12"
            lg="8"
            class="secondary--text text-h4 font-weight-bold"
          >
            Hi {{ userDetails.firstName }}, Verify your mobile number
          </v-col>
          <v-col
            cols="12"
            lg="8"
            class="mt-4 align-center"
            :class="{ 'd-flex': windowWidth > 600 }"
          >
            <div v-if="!changeMobileNumber" class="text-h5 grey--text">
              <div>OTP will be sent to your mobile number</div>
              <div class="d-flex align-center">
                {{ mobileNoCountryCode + mobileNo }}
                <v-btn
                  id="sign-in-button"
                  class="ml-2"
                  color="secondary"
                  :loading="isButtonLoading"
                  @click="mobileNoChange()"
                  >Change</v-btn
                >
              </div>
            </div>
            <div v-else class="d-flex align-center">
              <VuePhoneNumberInput
                v-model="intelInputMobileNo"
                :default-country-code="userDetails.countryCode"
                :preferred-countries="['IN', 'US', 'AU']"
                :error="!!mobileNumberValidation"
                error-color="#E53935"
                style="max-width: 400px"
                :translations="mobileNumberTranslation"
                @update="updateMobileNumber($event)"
              ></VuePhoneNumberInput>
              <v-btn
                id="sign-in-button"
                :class="{
                  'ml-3': windowWidth > 600,
                  'mt-3': windowWidth <= 600,
                }"
                color="secondary"
                :loading="isButtonLoading"
                @click="mobileNoChange()"
                >Save</v-btn
              >
              <v-btn
                v-if="changeMobileNumber"
                color="secondary"
                outlined
                class="ml-3"
                :class="{ 'mt-3': windowWidth <= 600 }"
                @click="cancelMobileNoChange()"
                >Cancel</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" lg="8" class="mt-n4">
            <div class="red--text caption mt-1">
              {{ mobileNumberValidation }}
            </div>
          </v-col>
          <v-col cols="12" lg="8" class="d-flex align-center mt-4">
            <ValidationProvider v-slot="{ errors }" name="OTP" rules="required">
              <v-text-field
                v-model="otp"
                placeholder="Enter your OTP"
                style="max-width: 300px"
                type="text"
                autocomplete="off"
                :error-messages="errors"
                @keydown="$event.keyCode === 13 ? verifyOtp() : false"
              ></v-text-field>
            </ValidationProvider>
            <v-btn
              v-if="showResendButton"
              id="sign-in-button"
              class="ml-2"
              text
              large
              @click="resendOtp()"
              >Resend</v-btn
            >
          </v-col>
          <v-col
            cols="12"
            lg="8"
            class="d-flex align-center text-h5 secondary--text"
          >
            <v-icon size="35" color="secondary">timer</v-icon>
            <span class="pl-2">{{ timerSeconds }}</span>
          </v-col>
          <v-col cols="12" lg="8" class="mt-4">
            <v-btn rounded color="secondary" @click="verifyOtp()">Verify</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <div v-else class="d-flex flex-column align-center justify-center mx-auto">
      <img
        v-if="!isAddingRegistration"
        :src="otpSuccessImg"
        alt="otp verification success"
        width="300"
        height="auto"
      />
      <img
        v-if="isAddingRegistration"
        :src="addRegistrationProps.successImg"
        alt="otp verification success"
        :width="addRegistrationProps.imgWidth"
        height="auto"
      />
      <v-card
        class="pa-2 rounded-xl my-4"
        color="light-blue lighten-4"
        width="400"
      >
        <v-progress-linear
          color="light-blue darken-2"
          height="20"
          :value="progressLoader"
          striped
          class="rounded-xl"
        ></v-progress-linear>
      </v-card>
      <div
        v-if="!isAddingRegistration"
        class="secondary--text font-weight-bold text-h5 mt-3"
      >
        {{ isOTPVerified ? "OTP Verified" : "Verifying OTP" }}
      </div>
    </div>
    <AppLoading v-if="isLoading"></AppLoading>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import {
  ADD_REGISTRATION,
  SEND_WELCOME_EMAIL,
  UPDATE_REGISTER_USER_DETAILS,
} from "@/graphql/registration/registrationQueries";
// import helper function
import { getErrorCodes } from "@/helper";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "RegistrationStep2",
  components: {
    VuePhoneNumberInput,
  },
  data() {
    return {
      isLoading: false,
      userDetails: {},
      timerSeconds: "02:00",
      mobileNo: "",
      mobileNoCountryCode: "",
      intelInputMobileNo: null,
      changeMobileNumber: false,
      isButtonLoading: false,
      mobileNumberProps: {},
      otp: "",
      domain: "",
      isMounted: true,
      otpCallCount: 0,
      showResendButton: false,
      mobileNumberTranslation: {
        phoneNumberLabel: "Mobile Number *",
      },
      // loading screen variables
      showLoadingScreens: false,
      progressLoader: 0,
      isOTPVerified: false,
      isAddingRegistration: false,
      addRegistrationProps: {
        successImg: "",
        imgWidth: "",
      },
    };
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    otpSuccessImg() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/otp-success.webp");
      else return require("@/assets/images/registration/otp-success.png");
    },
    registrationSuccessImg() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/registration-success.webp");
      else
        return require("@/assets/images/registration/registration-success.png");
    },
    quotes1Img() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/quotes-1.webp");
      else return require("@/assets/images/registration/quotes-1.png");
    },
    quotes2Img() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/quotes-2.webp");
      else return require("@/assets/images/registration/quotes-2.png");
    },
    quotes3Img() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/quotes-3.webp");
      else return require("@/assets/images/registration/quotes-3.png");
    },
    mobileNumberValidation() {
      if (this.isMounted) {
        return "";
      } else {
        if (!this.mobileNo) {
          return "Mobile number is required";
        } else if (Object.keys(this.mobileNumberProps)) {
          return this.mobileNumberProps.isValid
            ? ""
            : "Please provide a valid mobile number";
        }
        return "";
      }
    },
  },
  watch: {
    progressLoader(val) {
      if (this.isAddingRegistration && this.showLoadingScreens) {
        // when quarter(25%) and above percentage reaches change the success image
        if (val <= 35) {
          this.addRegistrationProps = {
            successImg: this.quotes1Img,
            imgWidth: "70%",
          };
        }
        // when half(50%) and above percentage reaches change the success image
        else if (val > 35 && val <= 70) {
          this.addRegistrationProps = {
            successImg: this.quotes2Img,
            imgWidth: "70%",
          };
        }
        // when 75% and above percentage reaches change the success image
        else if (val > 75) {
          this.addRegistrationProps = {
            successImg: this.quotes3Img,
            imgWidth: "70%",
          };
        }
      }
    },
  },
  mounted() {
    this.userDetails = { ...this.$store.state.registration.userDetails };
    this.mobileNo = this.userDetails.mobileNo;
    this.mobileNoCountryCode = this.userDetails.mobileNoCountryCode;
    this.intelInputMobileNo = this.mobileNo;
  },
  created() {
    // set the countdown as 120seconds for enabling resend otp
    this.countDownTimer(30);
  },
  methods: {
    async mobileNoChange() {
      if (!this.changeMobileNumber) {
        this.changeMobileNumber = true;
      } else {
        if (!this.mobileNumberValidation) {
          this.changeMobileNumber = false;
          this.userDetails.mobileNo = this.mobileNo;
          this.mobileNoCountryCode = this.userDetails.mobileNoCountryCode;
          this.intelInputMobileNo = this.mobileNo;
          await this.updateUserDetailsInStore();
          this.resendOtp();
          this.updateUserDetails();
        }
      }
    },
    cancelMobileNoChange() {
      this.changeMobileNumber = false;
      this.mobileNo = this.userDetails.mobileNo;
      this.mobileNoCountryCode = this.userDetails.mobileNoCountryCode;
      this.intelInputMobileNo = this.mobileNo;
      this.isMounted = true;
    },
    countDownTimer(duration) {
      var timer = duration,
        minutes,
        seconds,
        timerFunction = setInterval(() => {
          minutes = parseInt(timer / 60, 10);
          seconds = parseInt(timer % 60, 10);

          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;

          this.timerSeconds = minutes + ":" + seconds;
          if (--timer < 0) {
            clearInterval(timerFunction);
            this.showResendButton = true;
          }
        }, 1000);
    },
    updateMobileNumber(payload) {
      if (payload && Object.keys(payload).length > 0) {
        this.mobileNo = payload.nationalNumber;
        this.mobileNoCountryCode = "+" + payload.countryCallingCode;
      }
      this.mobileNumberProps = payload;
      this.isMounted ? (this.isMounted = false) : "";
    },
    // update the user details in store for global usage
    updateUserDetailsInStore() {
      let registrationDetails = {
        firstName: this.userDetails.firstName,
        lastName: this.userDetails.lastName,
        mobileNo: this.mobileNo,
        mobileNoCountryCode: this.mobileNoCountryCode,
        emailAddress: this.userDetails.emailAddress,
        orgName: this.userDetails.orgName,
        orgCode: this.userDetails.orgCode,
        countryCode: this.userDetails.countryCode,
      };

      this.$store.commit(
        "registration/UPDATE_USER_DETAILS",
        registrationDetails
      );
    },
    verifyOtp() {
      this.$refs.observer.validate().then((validationResponse) => {
        // response will return true/false based on validation
        if (validationResponse) {
          this.$store.commit("registration/UPDATE_OTP", this.otp);
          this.validateOTP();
        }
      });
    },
    // verify OTP
    async validateOTP() {
      this.showLoadingScreens = true;
      this.progressLoader = 10; // initial loader value
      // set loader while verifying OTP
      this.runProgressBar(3, 1000, 40);
      let otpVerifiedStatus = await this.$store.dispatch(
        "registration/verifyOTP"
      );
      if (otpVerifiedStatus && otpVerifiedStatus === "OTP Verified") {
        this.progressLoader = 100;
        this.isOTPVerified = true;
        this.addRegistration();
      } else {
        this.otp = "";
        this.showLoadingScreens = false;
        let snackbarData = {
          isOpen: true,
          type: "warning",
          message: otpVerifiedStatus,
        };
        this.showAlert(snackbarData);
      }
    },

    // send otp after verified/created user in firebase
    async resendOtp() {
      this.otp = "";
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
        }
      );
      let callCount = parseInt(this.otpCallCount, 10);
      this.isLoading = true;
      let snackbarData = {
        isOpen: true,
        type: "warning",
        message: "",
      };
      let otpSentStatus = await this.$store.dispatch(
        "registration/sendVerificationOTP"
      );
      if (otpSentStatus && otpSentStatus === "OTP Sent") {
        snackbarData.type = "success";
        snackbarData.message = "OTP resent successfully";
        if (this.timerSeconds === "00:00") {
          this.showResendButton = false;
          this.countDownTimer(30);
        }
      } else {
        snackbarData.message = otpSentStatus;
      }
      this.otpCallCount = callCount + 1;
      this.isLoading = false;
      this.showAlert(snackbarData);
    },

    // send otp after verified/created user in firebase
    async sendVerificationEmail() {
      let snackbarData = {
        isOpen: true,
        type: "warning",
        message: "",
      };
      let sendEmailStatus = await this.$store.dispatch(
        "registration/sendVerificationEmail"
      );
      if (sendEmailStatus && sendEmailStatus === "Email Sent") {
        snackbarData.type = "success";
        snackbarData.message = "Verification email sent successfully";
        setTimeout(() => {
          this.$emit("otp-verified");
        }, 200);
      } else {
        snackbarData.message = sendEmailStatus;
      }
      this.isLoading = false;
      this.showAlert(snackbarData);
    },

    /** ex: [loopUntil: 5, interval: 4000m.sec(4sec), incrementValue: 20]
     * the total duration is interval x loopUntil = 20seconds it is running
     * loopUntil: loop this function for 5 time
     * interval: time interval to take increment(ex: if loopUntil is 5, and interval is 4sec, then the progress bar was increment on every 4 sec until 20sec reaches.)
     * incrementValue: value to be incremented on every loop to the progress bar. consider the initial value as 10, and the incrementValue is 20, then the 20 will be added on every 4sec.(20,40,60.,)
     */
    runProgressBar(loopUntil, interval, incrementValue) {
      var timer = loopUntil,
        timerFunction = setInterval(() => {
          this.progressLoader += incrementValue;
          // if the timer is less than 0 or the maximum limit was reached then we have to clear the interval
          if (--timer < 0 || this.progressLoader >= 100) {
            clearInterval(timerFunction);
          }
        }, interval);
    },

    // check report API timeout(60secs)
    checkRegistrationTimeout() {
      return new Promise((resolve, reject) => {
        // set API time out as 60 seconds.
        const timer = setTimeout(() => {
          reject("TIMEOUT");
        }, this.$store.state.apiTimeOut);
        let vm = this;
        const {
          firstName,
          lastName,
          emailAddress,
          orgName,
          orgCode,
          countryCode,
          countryName,
        } = vm.userDetails;
        vm.$apollo
          .mutate({
            mutation: ADD_REGISTRATION,
            client: "apolloClientA",
            variables: {
              firstName: firstName,
              lastName: lastName,
              mobileNo: vm.mobileNo,
              mobileNoCountryCode: vm.mobileNoCountryCode,
              emailAddress: emailAddress,
              hrappUserUId: parseInt(
                vm.$store.state.registration.hrappUserUId,
                10
              ),
              orgName: orgName,
              orgCode: orgCode.toLowerCase(),
              countryCode: countryCode,
              frequency: vm.$store.state.registration.frequency,
              authenticationMethod:
                vm.$store.state.registration.authenticationMethod,
              dashboardType: vm.$store.state.registration.dashboardType,
              countryName: countryName,
            },
          })
          .then((response) => {
            // when API response is returned within 60 secs, need to clear timeout
            clearTimeout(timer);
            resolve(response.data); // return response
          })
          .catch((error) => {
            if (error && error.graphQLErrors) {
              // when API error is returned within 60 secs, need to clear timeout
              clearTimeout(timer);
              reject(error); // return errors
            }
          });
      });
    },

    // registering the user
    async addRegistration() {
      // progressLoader call
      let vm = this;
      setTimeout(() => {
        vm.isAddingRegistration = true;
        vm.progressLoader = 5;
        vm.runProgressBar(5, 8000, 20);
      }, 1000);
      await this.checkRegistrationTimeout()
        .then(() => {
          vm.sendVerificationEmail();
          vm.sendWelcomeEmail(); // send welcome email to the client after registration
        })
        .catch((addRegistrationError) => {
          if (addRegistrationError === "TIMEOUT") {
            vm.sendVerificationEmail();
            vm.sendWelcomeEmail(); // send welcome email to the client after registration
          } else {
            vm.$parent.loadingScreen = false;
            vm.handleAddRegistrationError(addRegistrationError);
          }
        });
    },

    // update user details
    updateUserDetails() {
      let vm = this;
      if (!vm.mobileNumberValidation) {
        const { firstName, lastName, emailAddress } = vm.userDetails;
        try {
          vm.$apollo
            .mutate({
              mutation: UPDATE_REGISTER_USER_DETAILS,
              client: "apolloClientA",
              variables: {
                firstName: firstName,
                lastName: lastName,
                mobileNo: vm.mobileNoCountryCode + vm.mobileNo,
                emailAddress: emailAddress,
              },
            })
            .then((response) => {
              const { errorCode, hrappUserId } =
                response.data.updateRegistrationUserDetails;
              if (!errorCode) {
                vm.$store.commit(
                  "registration/UPDATE_HRAPP_USER_ID",
                  hrappUserId
                );
              } else {
                // need not handle any success / failure
              }
            })
            .catch(() => {
              // need not handle any success / failure
            });
        } catch {
          // need not handle any success / failure
        }
      }
    },

    // send welcome email to the client after registration
    sendWelcomeEmail() {
      let vm = this;
      const { firstName, lastName, emailAddress, orgCode } = vm.userDetails;
      vm.$apollo
        .query({
          query: SEND_WELCOME_EMAIL,
          client: "apolloClientB",
          variables: {
            emailId: emailAddress,
            orgCode: orgCode.toLowerCase(),
            firstName: firstName,
            lastName: lastName,
            flowTrackRegistration: 1,
          },
        })
        .then(() => {
          // need not handle any success / failure
        })
        .catch(() => {
          // need not handle any success / failure
        });
    },

    // handle update user details error from BE
    handleAddRegistrationError(err = "") {
      this.isLoading = false;
      this.showLoadingScreens = false;
      this.isAddingRegistration = false;
      this.progressLoader = 0;
      this.addRegistrationProps = {
        successImg: "",
        imgWidth: "",
      };
      let snackbarData = {
        isOpen: true,
        type: "warning",
        message: "",
      };
      // check if it is a graphql error
      if (err && err.graphQLErrors) {
        let errorCode = getErrorCodes(err);
        switch (errorCode) {
          case "_DB0000": // technical errors
            snackbarData.message =
              "It’s us ! There seems to be some technical difficulties while registering. Please try after some time.";
            break;
          case "IVE0000": // validation error
          case "_UH0001": // unhandled error
          case "RR0001": // Organization level employee monitoring settings do not exist.
          case "RR0002": // Organization DB not created.
          case "RR0003": // Unable to update the new organization details fully or partially in the new DB.
          case "RR0004": // Unable to update the new organization details in the app manager.
          case "RR0005": // Duplicate org code is not allowed to insert in the tenant.
          case "RR0006": // Unable to update the tenant details.
          case "RR0007": // Unable to update the new organization tax details.
          case "RR0101": // Error while processing the request to create the new Organization DB.
          case "RR0102": // Error while processing the request to update the registration details.
          default:
            snackbarData.message =
              "Something went wrong while registering. If you continue to see this issue, please contact the platform administrator.";
            break;
        }
      } else {
        snackbarData.message =
          "Something went wrong while registering. If you continue to see this issue, please contact the platform administrator.";
      }
      this.showAlert(snackbarData);
    },

    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>
