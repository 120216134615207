export default {
  userDetails: {
    firstName: "",
    lastName: "",
    mobileNo: "",
    mobileNoCountryCode: "",
    emailAddress: "",
    orgName: "",
    orgCode: "",
    countryCode: "",
  },
  hrappUserUId: 0,
  frequency: "",
  authenticationMethod: "",
  dashboardType: "EMPLOYEEMONITORINGDASHBOARD",
  password: "",
  otp: null,
};
