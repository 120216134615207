<template>
  <div class="custom-loading-cls">
    <!-- Google Loader -->
    <div v-if="loaderType === 'google'" class="app-loader">
      <div class="ball"></div>
      <div class="ball"></div>
      <div class="ball"></div>
      <div class="ball"></div>
    </div>
    <!-- Circle colored ring Loader -->
    <div v-else-if="loaderType === 'circle'" id="circleloader">
      <div id="loader-circle"></div>
    </div>
    <!-- Default coloured dots Loader -->
    <div v-else class="loader">
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
      <div class="loader--dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoading",
  props: {
    loaderType: {
      type: String,
      default: "default",
    },
  },
};
</script>

<style scoped>
.custom-loading-cls {
  background: white;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  opacity: 0.8;
}

.app-loader {
  margin-top: 50vh;
  display: flex;
  justify-content: center;
}

/* google loader css */
.ball {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  float: left;
  margin-left: 20px;
  animation: move1 2s linear infinite;
}

.ball:nth-child(1) {
  background: #4285f4;
  animation-delay: 0s;
}

.ball:nth-child(2) {
  background: #ea4335;
  animation-delay: 0.25s;
}

.ball:nth-child(3) {
  background: #fbbc05;
  animation-delay: 0.5s;
}

.ball:nth-child(4) {
  animation-delay: 0.75s;
  background: #34a853;
}

@keyframes move1 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-25px);
  }
}

/* colorfull dotted loader css */
.loader {
  height: 20px;
  width: 250px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader--dot {
  animation-name: loader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: black;
  position: absolute;
  border: 2px solid white;
}
.loader--dot:first-child {
  background-color: #84db39;
  animation-delay: 0.5s;
}
.loader--dot:nth-child(2) {
  background-color: #804bbc;
  animation-delay: 0.4s;
}
.loader--dot:nth-child(3) {
  background-color: #fa3c5a;
  animation-delay: 0.3s;
}
.loader--dot:nth-child(4) {
  background-color: #e58315;
  animation-delay: 0.2s;
}
.loader--dot:nth-child(5) {
  background-color: #3bade3;
  animation-delay: 0.1s;
}
.loader--dot:nth-child(6) {
  background-color: #f6e61a;
  animation-delay: 0s;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}

/* Circle loader css */
#circleloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#loader-circle {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #9370db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
#loader-circle:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ba55d3;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
#loader-circle:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff00ff;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
