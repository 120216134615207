export default {
  UPDATE_USER_DETAILS(state, details) {
    state.userDetails = details;
  },
  UPDATE_PASSWORD(state, password) {
    state.password = password;
  },
  UPDATE_OTP(state, otp) {
    state.otp = otp;
  },
  UPDATE_HRAPP_USER_ID(state, hrappUserUId) {
    state.hrappUserUId = hrappUserUId;
  },
};
