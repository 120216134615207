const prod_baseurl = "https://api.hrapp.co/";
const stag_baseurl = "https://api.hrapp.co.in/";
const production = {
  domain: "hrapp.co",
  domainName: "FlowTrack",
  routingUrl: "/flowtrack",
  production: 1,
  graphql_endpoint: {
    signUp: prod_baseurl + "appmanager/registrationgraphql",
    planDetails: prod_baseurl + "planDetails/planDetails",
  },
  firebase_credentials: {
    apiKey: "AIzaSyAupi9_2ATYi05M7hfgO3pZFqF1dNGK7tk",
    authDomain: "hrappidentity.firebaseapp.com",
    databaseURL: "https://hrappidentity.firebaseio.com",
    projectId: "hrappidentity",
    storageBucket: "hrappidentity.appspot.com",
    messagingSenderId: "887685568909",
  },
  ipAddressApi: "https://ipinfo.io?token=5fb71e3c6fae15",
};
const staging = {
  domain: "hrapp.co.in",
  domainName: "FlowTrack",
  routingUrl: "/flowtrack",
  production: 1,
  graphql_endpoint: {
    signUp: stag_baseurl + "appmanager/registrationgraphql",
    planDetails: stag_baseurl + "planDetails/planDetails",
  },
  firebase_credentials: {
    apiKey: "AIzaSyB-QCDxis2HG3hHIreLPiidSlN_eCyi3m8",
    authDomain: "hrappsample.firebaseapp.com",
    databaseURL: "https://hrappsample.firebaseio.com",
    projectId: "hrappsample",
    storageBucket: "hrappsample.appspot.com",
    messagingSenderId: "514098503657",
  },
  ipAddressApi: "https://ipinfo.io?token=5fb71e3c6fae15",
};

const configuration =
  process.env.NODE_ENV === "production" ? production : staging;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...configuration,
};
