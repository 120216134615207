const mutation = {
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
    state.isMobileWindowSize = width > 600 ? false : true;
  },
  UPDATE_USER_INFO(state, payload) {
    state.userIpAddress = payload[0];
    state.userCountry = payload[1];
  },
  //to know whether browser Supports Webp or not
  UPDATE_WEBP_SUPPORTS(state, value) {
    state.isWebpSupport = value;
  },
  //mutation to show snackbar to present any error or success messages
  OPEN_SNACKBAR(state, snackbarData) {
    state.showSnackbar = snackbarData.isOpen;
    state.snackBarMessage = snackbarData.message;
    state.snackBarType = snackbarData.type;
  },
  //mutation to close snackbar and set it to default values
  CLOSE_SNACKBAR(state) {
    state.showSnackbar = false;
    state.snackBarMessage = "";
    state.snackBarType = "warning";
  },
};
export default mutation;
