<template>
  <div class="full-height">
    <v-row class="full-height ma-0" justify="center">
      <v-col
        v-if="windowWidth > 960"
        cols="4"
        class="d-flex justify-center flex-column"
        style="background: #005cff"
      >
        <div class="text-h5 text-center font-weight-bold px-sm-8 white--text">
          {{ registrationStepMessages }}
        </div>
        <img
          :src="
            registrationStep === 1
              ? registration1Image
              : registrationStep === 2
              ? 'registration-step2.png'
              : 'registration-step3.png'
          "
          alt="registration-step-1"
          :width="windowWidth > 1264 ? '400' : '300'"
          height="auto"
          class="text-center mx-auto mt-2"
        />
        <div
          style="bottom: 10px; width: 30%; position: absolute"
          class="d-flex justify-center mt-4"
        >
          <img
            :src="dataGuaranteeImage"
            alt="data guarantee"
            width="90"
            height="auto"
          />
        </div>
      </v-col>
      <v-col
        :cols="windowWidth <= 960 ? '12' : '8'"
        class="d-flex justify-center flex-column"
      >
        <div class="d-flex justify-center caption">
          <img
            :src="registrationDomain === 'Upshot' ? 'applogo.png' : 'logo.png'"
            alt="logo"
            class="mb-4"
            width="200"
          />
        </div>
        <RegistrationStep1
          v-if="registrationStep === 1"
          ref="registrationStep1"
          @next-step="nextStep()"
        ></RegistrationStep1>
        <RegistrationStep2
          v-else-if="registrationStep === 2"
          @otp-verified="nextStep()"
        ></RegistrationStep2>
        <RegistrationStep3
          v-else-if="registrationStep === 3"
        ></RegistrationStep3>
        <div
          v-if="registrationDomain === 'FlowTrack'"
          class="d-flex justify-center caption"
        >
          Powered by
          <img src="applogo.png" alt="app-logo" width="80" class="pl-1" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RegistrationStep1 from "./RegistrationStep1";
import RegistrationStep2 from "./RegistrationStep2";
import RegistrationStep3 from "./RegistrationStep3";
import config from "../../config";

export default {
  name: "Registration",
  components: {
    RegistrationStep1,
    RegistrationStep2,
    RegistrationStep3,
  },
  data: () => ({
    registrationStep: 1,
  }),
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    registrationDomain() {
      return config.domainName;
    },
    // step 1 image
    registration1Image() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/registration-step1.webp");
      else
        return require("@/assets/images/registration/registration-step1.png");
    },
    registrationStepMessages() {
      if (this.registrationStep === 1) {
        return "Accelerate your workforce productivity by 2 X with a real time evaluation of employee engagement";
      } else if (this.registrationStep === 2) {
        return "Protect your client's intellectual property and business-sensitive information from theft";
      } else {
        return "Reward and Recognize your workforce based on data and not on perception";
      }
    },
    dataGuaranteeImage() {
      if (this.isBrowserSupportWebp)
        return require("@/assets/images/registration/data-guaranteed.webp");
      else return require("@/assets/images/registration/data-guaranteed.png");
    },
  },
  mounted() {
    localStorage.setItem("timeOutError", false);
    if (this.registrationDomain === "FlowTrack") {
      var headScript = document.createElement("script");
      headScript.type = "text/javascript";
      headScript.async = true;
      headScript.defer = true;
      headScript.id = "hs-script-loader";
      headScript.src = "//js.hs-scripts.com/8169364.js";
      document.head.appendChild(headScript);
    }
  },
  errorCaptured() {
    let snackbarData = {
      isOpen: true,
      message:
        "Something went wrong while loading registration details. Please try after some time.",
      type: "warning",
    };
    this.showAlert(snackbarData);
    return false;
  },
  methods: {
    nextStep() {
      this.registrationStep += 1;
    },
    // function to show error or success message inside dashboard
    showAlert(snackbarData) {
      this.$store.commit("OPEN_SNACKBAR", snackbarData);
    },
  },
};
</script>

<style lang="css">
@import "../../assets/css/VueCommon.css";

.full-height {
  height: 100%;
}
.registrationStep2-bc {
  background: #fde5ce;
}
</style>
