// import extend function - to add custom rules in vee-validate
import { extend } from "vee-validate";

// import the already available rules
import { required, max, email, min } from "vee-validate/dist/rules";

// ---- existing rules ----

// required field validation
export const requiredField = extend("required", {
  ...required,
  message: "{_field_} is required.",
});

export const emailIdField = extend("email", {
  ...email,
  message: "Please provide a valid email address.",
});

export const maxVal = extend("max", max);

export const minVal = extend("min", min);
// ---- custom rules ----

// max length validation
export const maxLengthValidation = extend("max", (value, args) => {
  if (value.length <= args.length) {
    return true;
  }
  return (
    "The value should not exceed more than " + args.length + " characters."
  );
});

// min length validation
export const minLengthValidation = extend("min", (value, args) => {
  if (value.length >= args.length) {
    return true;
  }
  return "The value should be more than " + args.length + " characters.";
});

// alphabets, space and dots are allowed
export const alphaSpaceDotValidation = extend("alphaSpaceDot", (value) => {
  if (/^[a-zA-Z\.\ ]+$/.test(value)) {
    return true;
  }
  return "Only alphabets, spaces and symbol( . ) are allowed.";
});
export const multilingualNameValidation = extend(
  "multilingualName",
  (value) => {
    // Check if the value contains any HTML tags
    if (/<[^>]*>/g.test(value)) {
      return "HTML tags are not allowed.";
    }
    // Check if the value contains only allowed characters
    if (/^[\p{L}\p{M}\s.,#\+&/\-():'ñd’!@#$%^&*_±=|\\;?"]+$/u.test(value)) {
      return true;
    }
    return "Only alphabets, spaces and symbol(. , #  + & /  - ( ):'ñd’ ! @ #  ± $ % ^ & * _ =)  are allowed.";
  }
);
// alphabets and numeric are allowed
export const alphaNumericValidation = extend("alphaNumeric", (value) => {
  if (/^[a-zA-Z0-9]*$/.test(value)) {
    return true;
  }
  return "Only alphanumerics are allowed.";
});

// alphabets and numeric are allowed
export const passwordCompareValidation = extend(
  "passwordCompare",
  (value, args) => {
    if (value === args[0]) {
      return true;
    }
    return "Confirm password doesn't match with your password";
  }
);

// alphabets, numeric, &, hyphen, comma, dot and space are allowed
export const alphaNumHyphenSpaceDotCommaAndValidation = extend(
  "alphaNumHyphenSpaceDotCommaAnd",
  (value) => {
    if (/^[\w\#\+\/\(\)\:\'\.\&\,\-\ ]*$/.test(value)) {
      return true;
    }
    return "Only alphanumeric, spaces and symbols( & - , . # + / ( ) : ' )  are allowed.";
  }
);
